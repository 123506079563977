import { snsLogin } from "api/auth";
import { RejectContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMutation } from "react-query";
import useModalStore from "store/useModalStore";
import useSignedStore from "store/useSignedStore";
import cookie from "react-cookies";

const useSns = () => {
  const { prevUrl, changeLink, cameFromSameDomain } = useUrl();
  const { setModal } = useModalStore();
  const { setSigned } = useSignedStore();

  const snsMutation = useMutation(snsLogin, {
    onSuccess: ({ data, jwtProvider }) => {
      const userState = data.userState;

      if (userState === 0) {
        const prev = cameFromSameDomain(["/signup", "/find"])
          ? prevUrl ?? "/"
          : "/";
        cookie.save("moomoostJwt", jwtProvider, {
          path: "/",
        });

        setSigned({ ...data, isLogin: true });

        // window.location.href = prev;

        // 어드민에서 아티스트 승인/보류 시 모달
        if (data.artistResult) {
          const isApprove = data.artistResult.result === "approve";
          const content = data.artistResult.content;

          setModal({
            title: "알림",
            content: <RejectContent message={content} isApprove={isApprove} />,
            noneInfo: true,
            callback: () => {
              window.location.href = prev;
            },
          });
        }

        // 멤버 승인, 특정 콘텐츠 아티스트 매칭/보류 시 모달
        if (data.creationResult) {
          const isApprove = data.creationResult.result === "approve";
          const content = data.creationResult.content;

          setModal({
            title: "알림",
            content: <RejectContent message={content} isApprove={isApprove} />,
            noneInfo: true,
            callback: () => {
              window.location.href = prev;
            },
          });
        }

        if (!data.artistResult?.content && !data.creationResult?.content) {
          window.location.href = prev;
        }
      } else if (userState === 1) {
        setModal({
          title: "로그인",
          content: "차단된 사용자 계정입니다.",
        });
      } else {
        setModal({
          title: "로그인",
          content: "탈퇴된 사용자 계정입니다.",
        });
      }
    },
    onError: ({ response: { data } }) => {
      if (data.type === "LOGIN_Inconsistency") {
      } else {
        setModal({
          title: "로그인",
          content: data.message,
        });
      }
    },
  });

  const submit = ({ email, name, socialProvider }) => {
    snsMutation.mutate({ email, name, socialProvider });
  };

  return submit;
};

export default useSns;
